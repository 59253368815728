import { InputNumber } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledInput = styled(InputNumber)`
  border-radius: 8px;
  background: #fff;
  width: 100%;

  .ant-input {
    position: relative;
    padding: 8px 12px;
    border-radius: 8px;
    transition: all 0.3s;
    border: 1px solid rgba(207, 207, 213, 0.5);

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &.ant-input-disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.65);
      cursor: not-allowed;
      border-color: #d9d9d9;
    }
  }
  .ant-input-group-addon {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const NumericInput = (props) => {
  const { onChange } = props;
  return (
    <StyledInput
      min={0}
      maxLength={16}
      type="number"
      {...props}
      onChange={onChange}
    />
  );
};
export default NumericInput;
