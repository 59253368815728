import React, { useContext } from 'react';
import { NewCitizenContext } from "../../NewCitizenContext";
import { FormItem, InputTextFields } from 'pages/Register/components/InputField.styled';
import { farmingWorkOptions, farmingActivityOptions } from 'jsonLib';
import { Checkbox } from 'antd';
import styled from 'styled-components';

const StyledCheckboxGroup = styled(Checkbox.Group)`
label.ant-checkbox-group-item {
  width: 100%;
}
`

const FarmworkerForm = () => {
  const {
    stateDispatchMain,
    state,
  } = useContext(NewCitizenContext);

  return (
    <>
      <FormItem label="Kind of Work" name="kinds_of_work" rules={[{ required: true, message: "This field is required" }]}>
        <StyledCheckboxGroup
          options={farmingWorkOptions}
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                kinds_of_work: value,
              },
            });
          }}
        />
      </FormItem>
      {
        state.citizenDetails.kinds_of_work.some((value) => value === 10) ? (
          <FormItem label="Please specify" >
            <InputTextFields
              onChange={e =>
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    kind_of_work_other: e.target.value,
                  },
                })
              }
            />
          </FormItem>

        ) : null
      }
      <FormItem label="Type of Farming Activity" name="farm_activities" rules={[{ required: true, message: "This field is required" }]}>
        <StyledCheckboxGroup
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                farm_activities: value,
              },
            });
          }}
          options={farmingActivityOptions}
        />
      </FormItem>
      {
        state.citizenDetails.farm_activities.some((value) => value === 3) ? (
          <FormItem label="Please specify crop" >
            <InputTextFields
              onChange={e =>
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    farm_activity_other: e.target.value,
                  },
                })
              }
            />
          </FormItem>
        ) : null
      }
      {
        state.citizenDetails.farm_activities.some((value) => value === 4) ? (
          <FormItem label="Please specify livestock" >
            <InputTextFields
              onChange={e =>
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    farm_activity_other_livestock: e.target.value,
                  },
                })
              }
            />
          </FormItem>
        ) : null
      }
    </>
  )
}
export default FarmworkerForm;
