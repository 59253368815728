import { Avatar, Button, Modal, Spin, Table, message } from "antd";
import React, { useState } from "react";
import fallbackIdPrevImg from "assets/no_img_found.png";
import styled from "styled-components";
import { generateIDCard, viewIDCard } from "services/public_registration.service";

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th {
    padding: 8px;
    font-size: 12px;
  }
  .ActionsContainer {
    .ant-btn {
      text-align: left;
      span {
        color: #40a9ff !important;
        font-size: 12px;
        @media only screen and (min-width: 900px) {
          font-size: 14px;
        }
      }
    }
  }
  
  @media only screen and (min-width: 900px) {
    .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th {
      padding: 12px;
      font-size: 14px;
    }
  }
`;

function IDTable({ data, loading, pagination, resendInterval }) {
  const [loadingIdCard, setLoadingIdCard] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCitizen, setShowCitizen] = useState(null);
  const [idSource, setIdSource] = useState(null);

  const getIDCard = async (citizen, isDownload = false) => {
    const { id: citizenId } = citizen;
    await viewIDCard(citizenId)
      .then(data => {
        const tmpUrl = URL.createObjectURL(data);
        setIdSource(tmpUrl);

        if (isDownload) {
          const link = document.createElement("a");
          link.href = tmpUrl;
          link.download = `${citizenId}.png`;
          link.click();
        }

        setLoadingIdCard(false);
      })
      .catch(error => message.error(error));
  }

  const showIdModal = async citizen => {
    setLoadingIdCard(true);
    await getIDCard(citizen)
    setShowCitizen(citizen);
    setIsModalOpen(true);
  };

  const downloadCitizenID = async (citizen) => {
    await getIDCard(citizen, true);
  };

  const resendSms = async (citizen) => {
    let timer = 60;
    resendInterval.current = setInterval(() => {
      if (timer > 0) {
        timer -= 1;
      }
      const resendBtn = document.querySelector(`#resend-btn-${citizen.id}`);
      const resendBtnText = resendBtn?.querySelector('span');

      if (resendBtnText) {
        resendBtnText.innerText = `Resend SMS (${timer})`;
      }
      resendBtn?.setAttribute('disabled', 'true');

      if (timer === 0) {
        clearInterval(resendInterval.current);
        if (resendBtnText) {
          resendBtnText.innerText = `Resend SMS`;
        }
        resendBtn?.removeAttribute('disabled');
      }
    }, 1000);

    await generateIDCard(citizen.id);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderActions = (citizen) => {
    return (
      <div className="ActionsContainer">
        <Button
          size="small"
          type="link"
          block
          onClick={() => showIdModal(citizen)}
        >
          View ID
        </Button>
        <Button
          size="small"
          type="link"
          block
          onClick={() => downloadCitizenID(citizen)}
        >
          Download ID
        </Button>
        <Button
          size="small"
          type="link"
          block
          onClick={() => resendSms(citizen)}
          id={`resend-btn-${citizen.id}`}
        >
          Resend SMS
        </Button>
      </div>
    )
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "card_number",
      key: "card_number",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "20%",
    },
    {
      title: "Profile Photo",
      dataIndex: "profile",
      key: "profile",
      render: profile => <Avatar src={profile} shape="square" size={64} />,
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
      width: "20%",
      render: (_, record) => <div>{record?.municipality?.province?.name}</div>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => renderActions(record),
    },
  ];

  return (
    <Wrapper>
      <Table loading={loading} columns={columns} dataSource={data} pagination={pagination}>
        IDTable
      </Table>

      <Modal
        title="ID Preview"
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={[
          <Button
            key="download"
            onClick={() => downloadCitizenID(showCitizen)}
          >
            Download ID
          </Button>,
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
        bodyStyle={{ padding: "8px" }}>
        {loadingIdCard ? (
          <Spin style={{ margin: "3rem auto", width: "100%" }} />
        ) : (
          <img src={idSource || fallbackIdPrevImg} style={{ width: "100%" }} />
        )}
      </Modal>
    </Wrapper>
  );
}

export default IDTable;
