import MainLayout from "layouts/MainLayout/MainLayout";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CitizenProfile from "../components/CitizenProfile";
import { useSocialServiceContext } from "../Context";
import { getCitizen, getCitizenByCardNumber } from "services/citizen";
import { styled } from "styled-components";
import { Alert, Col, Row, Spin } from "antd";
import StyledButton from "components/button/Button";
import History from "../components/History";
import { MobileContainer, StyledElement2 } from "../components/reusables";

const BackButton = styled(StyledButton)`
  height: 43px;
  border-radius: 11px;
  background: rgba(217, 217, 217, 0.21);
  min-width: 108px;
  span {
    color: initial;
  }
  @media only screen and (min-width: 768px) {
    background: white;
    span {
      color: initial;
    }
  }
`;

const RedeemButton = styled(StyledButton)`
  height: 43px;
  @media only screen and (min-width: 768px) {
    background: #E7B110 !important;
    border-color: #E7B110;
  }
`;

const CitizenDetail = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { setCitizen, citizen, history: histories } = useSocialServiceContext();
  const isFetchedStarted = useRef(false);
  const hasRedeemed = histories?.some((h) => h.proposed_amount === '1600.00');

  useEffect(() => {
    if (isFetchedStarted.current) {
      return;
    }
    isFetchedStarted.current = true;

    if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id)) {
      getCitizen(id)
        .then(citizen => {
          setIsLoading(false);
          setCitizen(citizen);
        })
        .catch(error => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      getCitizenByCardNumber(id)
        .then(citizen => {
          setCitizen(citizen);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  if (!id) {
    throw Error("No ID provided");
  }

  return (
    <StyledElement2>
      <MobileContainer>
        <MainLayout>
          {isLoading && <Spin />}
          {!citizen && !isLoading ? (
            <Row gap={15}>
              <Col span={24}>
                <Alert message={"Error"} showIcon type="error" description={"Having trouble loading citizen data"} />
              </Col>
              <Col span={24} justify={"space-between"}>
                <Link to={-1}>
                  <BackButton>Back</BackButton>
                </Link>
              </Col>
            </Row>
          ) : null}
          {citizen ? (
            <>
              <CitizenProfile {...citizen} />
              <Row gutter={15} justify={hasRedeemed ? 'center' : "space-between"}>
                {
                  !hasRedeemed && (
                    <Col>
                      <Link to={`/citizen/${id}/redeem`}>
                        <RedeemButton>Redeem Social Service</RedeemButton>
                      </Link>
                    </Col>
                  )
                }
                <Col>
                  <Link to={-1}>
                    <BackButton>Back</BackButton>
                  </Link>
                </Col>
              </Row>
              {citizen ? <History /> : null}
            </>
          ) : null}
        </MainLayout>
      </MobileContainer>
    </StyledElement2>
  );
};

export default CitizenDetail;
