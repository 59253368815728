import React, { useContext } from "react";
import {
  FormItem,
  InputSelectField,
  InputTextFields,
} from "../../components/InputField.styled";
import { RegisterStepContainer } from "../../components/Register.styled";
import { NewCitizenContext } from "../NewCitizenContext";
import { yesNoOptions } from "jsonLib";
import Paragraph from "antd/lib/typography/Paragraph";
import { Col, Row } from "antd";
import NumericInput from "components/form/fields/NumericInput";

const HouseholdInformation = () => {

  const { state, stateDispatchMain } =
    useContext(NewCitizenContext);
  const haveChildren = state.citizenDetails.have_children;

  return (
    <RegisterStepContainer>
      <FormItem label="Do you have children?" name="have_children">
        <InputSelectField
          name="have_children"
          bordered={false}
          optionFilterProp="children"
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                have_children: value,
              },
            });
          }}
          defaultValue={state.citizenDetails?.have_children || false}
          options={yesNoOptions}
        />
      </FormItem>
      <div style={{ display: haveChildren ? 'block' : 'none' }}>
        <FormItem label="Number of Kids in Elementary" name="children_elementary">
          <NumericInput
            defaultValue={0}
            maxLength={2}
            name="children_elementary"
            onChange={(value) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  children_elementary: value,
                },
              })
            }
            }
          />
        </FormItem>
        <FormItem label="Number of Kids in Highschool" name="children_high_school">
          <NumericInput
            defaultValue={0}
            maxLength={2}
            name="children_high_school"
            onChange={(value) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  children_high_school: value,
                },
              })
            }
            }
          />
        </FormItem>
        <FormItem label="Number of Kids in College" name="children_college">
          <NumericInput
            defaultValue={0}
            maxLength={2}
            name="children_college"
            onChange={(value) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  children_college: value,
                },
              })
            }
            }
          />
        </FormItem>
        <FormItem label="Number of Kids who finished College?" name="children_college_grad">
          <NumericInput
            defaultValue={0}
            maxLength={2}
            name="children_college_grad"
            onChange={(value) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  children_college_grad: value,
                },
              })
            }
            }
          />
        </FormItem>
      </div>
    </RegisterStepContainer>
  );
};

export default HouseholdInformation;
