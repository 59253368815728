import React, { useEffect, useState, useContext } from "react";
import OTPInput from "../../components/OTPInput";
import { NewCitizenContext } from "../NewCitizenContext";
import { OtpStepContainer } from "pages/Register/components/Register.styled";

const OTPVerification = () => {
  const { sendOTPMobile, setOTP } = useContext(NewCitizenContext);
  const [isPressed, setIsPressed] = useState(false);
  const [timer, setTimer] = useState(0);

  const resendOTP = async () => {
    setIsPressed(true);
    setTimer(60);
    await sendOTPMobile();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }

      if (timer === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const handleOTPComplete = otp => {
    // Perform any actions with the OTP value in the parent component
    setOTP(otp);
  };

  return (
    <OtpStepContainer>
      <p>Ilagay ang 6-digit na OTP code na ipinadala sa iyo sa SMS upang makumpleto ang iyong request.</p>
      <div>
        <OTPInput onComplete={handleOTPComplete} />
      </div>
      <p>
        Hindi pa ba natatanggap ang confirmation code? <br />
        <span
          onClick={() => resendOTP()}
          style={
            timer > 0
              ? { fontWeight: "bold", color: "#ECECEC", pointerEvents: "none" }
              : { fontWeight: "bold", color: "#107400", cursor: "pointer" }
          }>
          Ipadala muli
          <span>{isPressed && timer !== 0 && `(${timer})`}</span>
        </span>
      </p>
    </OtpStepContainer>
  );
};

export default OTPVerification;
