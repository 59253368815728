import React, { useContext, useEffect } from "react";
import {
  FormItem,
  InputTextFields,
  InputPhoneNumberField,
  InputSelectField,
  BirthdateSelectContainer,
  InputDateSelectField,
} from "../../components/InputField.styled";
import { RegisterStepContainer } from "../../components/Register.styled";
import PropTypes from "prop-types";
import { NewCitizenContext } from "../NewCitizenContext";
import { daysOptions, monthsOptions, yearOptions } from "../../../../constant/selectConstants";
import { civilStatusOptions, yesNoOptions } from "jsonLib";

const PersonalInformation = ({ form, formData }) => {
  const { first_name, middle_name, last_name, name_extension, spouse, fb_profile_link } = formData;

  const { state, genderOptions, stateDispatchMain, formatPhoneNumber, parsePhoneNumber } =
    useContext(NewCitizenContext);

  useEffect(() => {
    const year = form?.getFieldValue("year");
    const month = form?.getFieldValue("month");
    const date = form?.getFieldValue("date");

    if (date) {
      const dateOptions = daysOptions(month, year);

      if (!dateOptions.find(d => d.value === date)) {
        form?.setFieldsValue({ date: undefined });
      }
    }
  }, [form?.getFieldValue("month"), form?.getFieldValue("year")]);

  return (
    <RegisterStepContainer>
      <FormItem label="Pangalan" name="first_name" rules={[{ required: true, message: "First name is required" }]}>
        <InputTextFields
          name="first_name"
          value={first_name}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                first_name: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem label="Gitnang Pangalan / Middle Name" name="middle_name" rules={[{ required: true, message: "First name is required" }]}>
        <InputTextFields
          name="middle_name"
          value={middle_name}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                middle_name: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem label="Apelyido" name="last_name" rules={[{ required: true, message: "Last name is required" }]}>
        <InputTextFields
          name="last_name"
          value={last_name}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                last_name: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem
        label="Ext (Sr,Jr,I,II,III)"
        name="name_extension"
        rules={[
          {
            max: 10,
            message: 'Name extension cannot be longer than 10 characters'
          }
        ]}
      >
        <InputTextFields
          name="name_extension"
          value={name_extension}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                name_extension: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem label="Kasarian/Sex" name="gender" rules={[{ required: true, message: "Gender is required" }]}>
        <InputSelectField
          showSearch
          name="gender"
          bordered={false}
          optionFilterProp="children"
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                gender: value,
              },
            });
          }}
          options={genderOptions}
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <FormItem
        label="Mobile Number"
        name="phone_number"
        validateTrigger={["onBlur"]}
        rules={[
          { required: true, message: "Mobile number is required" },
          {
            validator: async (_, value) => {
              if (value.toString().length < 10) {
                return Promise.reject("Incorrect mobile number format");
              } else {
                return Promise.resolve();
              }
            },
            message: "Incorrect mobile number format",
          },
        ]}>
        <InputPhoneNumberField
          name="phone_number"
          autoComplete="off"
          formatter={formatPhoneNumber}
          parser={parsePhoneNumber}
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                phone_number: value,
              },
            });
          }}
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          defaultValue={63}
          maxLength={12}
          minLength={12}
        />
      </FormItem>
      <FormItem label="Birthdate" className="FormItem-Birthdate">
        <BirthdateSelectContainer>
          <FormItem className="personal-info-date-select" label="Year" name="year" rules={[{ required: true, message: "Year is required." }]}>
            <InputDateSelectField
              options={yearOptions()}
              showSearch
              allowClear
              onChange={value => {
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    birthdate_year: value,
                  },
                });
              }}
            />
          </FormItem>
          <FormItem className="personal-info-date-select" label="Month" name="month" rules={[{ required: true, message: "Month is required." }]}>
            <InputDateSelectField
              options={monthsOptions()}
              filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
              showSearch
              allowClear
              onChange={value => {
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    birthdate_month: value,
                  },
                });
              }}
            />
          </FormItem>
          <FormItem className="personal-info-date-select" label="Date" name="date" rules={[{ required: true, message: "Date is required." }]}>
            <InputDateSelectField
              options={daysOptions(form?.getFieldValue("month"), form?.getFieldValue("year"))}
              disabled={!form?.getFieldValue("year") || !form?.getFieldValue("month")}
              showSearch
              allowClear
              onChange={value => {
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    birthdate_day: value,
                  },
                });
              }}
            />
          </FormItem>
        </BirthdateSelectContainer>
      </FormItem>
      <FormItem label="Civil Status" name="civil_status" rules={[{ required: true, message: "Civil status is required" }]}>
        <InputSelectField
          showSearch
          name="civil_status"
          bordered={false}
          optionFilterProp="children"
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                civil_status: value,
              },
            });
          }}
          options={civilStatusOptions}
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      {
        state.citizenDetails?.civil_status === 2 ? (<FormItem label="Name of Spouse if Married" name="spouse" rules={[{ required: true, message: "Name of spouse is required" }]}>
          <InputTextFields
            name="spouse"
            value={spouse}
            onChange={e =>
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  spouse: e.target.value,
                },
              })
            }
          />
        </FormItem>) : null
      }
      {
        state.citizenDetails?.is_with_govt_id ? (
          <FormItem label="Specify ID number if yes" name="government_id_number" rules={[{ required: true, message: "Government ID number is required" }]}>
            <InputTextFields
              name="government_id_number"
              onChange={e =>
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    government_id_number: e.target.value,
                  },
                })
              }
            />
          </FormItem>
        ) : null
      }
      <FormItem label="Member of any Farmers Association/Cooperative">
        <InputSelectField
          showSearch
          bordered={false}
          optionFilterProp="children"
          onChange={value => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                is_association_member: value,
              },
            });
          }}
          value={state.citizenDetails?.is_association_member}
          options={yesNoOptions}
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      {
        state.citizenDetails?.is_association_member ? (
          <FormItem label="If yes, specify" name="association" rules={[{ required: true, message: "Association / Cooperative is required" }]}>
            <InputTextFields
              name="association"
              onChange={e =>
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    association: e.target.value,
                  },
                })
              }
            />
          </FormItem>
        ) : null
      }
      <FormItem label="RSBSA ID Number" name="rsbsa_id">
        <InputTextFields
          name="rsbsa_id"
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                rsbsa_id: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem
        label="Facebook Profile Link"
        name="fb_profile_link"
        rules={[
          {
            validator: async (_, value) => {
              const pattern = /^https:\/\/(www\.)?(facebook\.com|fb\.com)\/[a-zA-Z0-9.]{5,}\/?$/;
              if (value && !pattern.test(value)) {
                return Promise.reject("Invalid facebook profile link");
              } else {
                return Promise.resolve();
              }
            },
            message: "Invalid facebook profile link",
          },
        ]}
        extra="https://facebook.com/juan.delacruz"
      >
        <InputTextFields
          name="fb_profile_link"
          value={fb_profile_link}
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                fb_profile_link: e.target.value,
              },
            })
          }
        />
      </FormItem>
    </RegisterStepContainer >
  );
};

PersonalInformation.propTypes = {
  form: PropTypes.object,
  formData: PropTypes.any,
  errors: PropTypes.any,
};

export default PersonalInformation;
