import React, { useCallback, useContext } from "react";
import { RegisterStepContainer } from "../../components/Register.styled";
import { Radio } from "antd";
import { NewCitizenContext, defaultFarmParcel } from "../NewCitizenContext";
import styled from "styled-components";
import FarmworkerForm from "./farmProfile/FarmworkerForm";
import { FormItem, InputSelectField } from "pages/Register/components/InputField.styled";
import NumericInput from "components/form/fields/NumericInput";
import FarmParcelsForm from "./farmProfile/FarmParcelsForm";


const StyledLivelihoodOptions = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  .ant-radio-button-wrapper {
    margin-bottom: 8px;
    border-color: transparent;
    height: auto;
    text-align: center;
    border-width: 1px;
    &:not(:first-child):before {
      display: none;
    }

    &.ant-radio-button-wrapper-checked {
      padding-top: 8px;
      border-color: #107400 !important;
      p {
        margin-bottom: 0%;
      }
    }
  }
`;

const FarmProfile = ({
  children,
}) => {
  const {
    stateDispatchMain,
    state,
  } = useContext(NewCitizenContext);

  const onChangeParcelCount = (value) => {
    const count = value;
    const farmParcels = [...state.citizenDetails.farm_parcels];
    const newFarmParcels = [];

    for (let index = 0; index < count; index++) {
      const farmParcel = farmParcels[index] ?? defaultFarmParcel;
      newFarmParcels.push(farmParcel);
    }

    stateDispatchMain({
      key: "citizenDetails",
      payload: {
        ...state.citizenDetails,
        farm_parcel_count: count,
        farm_parcels: newFarmParcels
      },
    });
  };

  return (
    <RegisterStepContainer>
      <StyledLivelihoodOptions
        name="farm_livelihood"
        onChange={(e) => {
          const farm_livelihood = e.target.value;
          stateDispatchMain({
            key: "citizenDetails",
            payload: {
              ...state.citizenDetails,
              farm_activity: 6,
              farm_livelihood,
            },
          })
        }}
        rules={[{ required: true, message: "This field is required" }]}
        defaultValue={state.citizenDetails?.farm_livelihood || 4}
      >
        <Radio.Button value={4}>
          <img src="/static/images/livelihood-farmowner.png" alt="farmer" />
          <p><strong>Farm Owner</strong></p>
        </Radio.Button>
        <Radio.Button value={5}>
          <img src="/static/images/livelihood-farmlaborer.png" alt="farmer" />
          <p><strong>Farm Tenant / Rentor</strong></p>
        </Radio.Button>
        <Radio.Button value={2}>
          <img src="/static/images/livelihood-farmtenantrentor.png" alt="farmer" />
          <p><strong>Farm Laborer</strong></p>
        </Radio.Button>
      </StyledLivelihoodOptions>

      <FarmworkerForm />
      {/* <div style={{ display: state.citizenDetails?.farm_livelihood === 1 ? 'block' : 'none' }}>
        <FarmerForm />
      </div>
      <div style={{ display: state.citizenDetails?.farm_livelihood === 2 ? 'block' : 'none' }}>
        <FarmworkerForm />
      </div>
      <div style={{ display: state.citizenDetails?.farm_livelihood === 3 ? 'block' : 'none' }}>
        <FisherfolkForm />
      </div> */}

      <div style={{ display: state.citizenDetails?.farm_livelihood !== 2 ? 'block' : 'none' }}>
        <FormItem name="farming_income_type" label="Farming Income Type">
          <InputSelectField
            defaultValue="yearly"
            name="farming_income_type"
            bordered={false}
            optionFilterProp="children"
            onChange={value => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  farming_income_type: value,
                },
              });
            }}
            options={[
              {
                label: 'Monthly Net Income',
                value: 'monthly'
              },
              {
                label: 'Yearly Net Income',
                value: 'yearly'
              }
            ]}
            value={state.citizenDetails.farming_income_type}
          />
        </FormItem>
        <FormItem name="farming_income" label="Farming Income"
          rules={[
            {
              required: state.citizenDetails?.farm_livelihood !== 2,
              message: "This field is required"
            }]}>
          <NumericInput
            defaultValue={0}
            name="farming_income"
            onChange={(value) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  farming_income: value,
                },
              })
            }}
            addonBefore="PHP"
          />
        </FormItem>

        <FormItem label="No. of Farm Parcels" rules={[
          {
            max: 5,
            message: "Maximum of 5 parcels only"
          }
        ]}>
          <NumericInput
            defaultValue={0}
            max={5}
            onChange={(value) => {
              onChangeParcelCount(value)
            }}
          />
        </FormItem>

      </div>
      <div style={{ display: state.citizenDetails?.farm_livelihood !== 4 ? 'block' : 'none' }}>
        {
          // Farm Tenant / Rentor
          state.citizenDetails?.farm_livelihood === 5 ? (
            <div><strong>Mode of Payment to the Land Owner</strong></div>
          ) : null
        }
        {
          // Farm Laborer
          state.citizenDetails?.farm_livelihood === 2 ? (
            <div><strong>Monthly Net Income</strong></div>
          ) : null
        }
        <FormItem name="kg_of_rice_payment_to_owner" label="Kilos of Palay">
          <NumericInput
            defaultValue={0}
            name="kg_of_rice_payment_to_owner"
            onChange={(value) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  kg_of_rice_payment_to_owner: value,
                },
              })
            }
            }
          />
        </FormItem>
        <FormItem name="cash_payment_to_owner" label="Cash">
          <NumericInput
            defaultValue={0}
            name="cash_payment_to_owner"
            onChange={(value) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  cash_payment_to_owner: value,
                },
              })
            }
            }
            addonBefore="PHP"
          />
        </FormItem>
      </div>
      <FarmParcelsForm />

      {children}
    </RegisterStepContainer >
  );
};

export default FarmProfile;
