import React from "react";
import { RegisterHomePageContainer } from "../components/Register.styled";
import CodeForm from "../CodeForm";


const RegisterHomepage = () => {

  return (
    <RegisterHomePageContainer>
      <div className="left-side-container">
        <div className="text-container">
          <div className="logo-banner-container">
            <img src="/static/images/logo-banner.png" className="logo-banner" />
            <img src="/static/images/farm-icon.png" className="logo-sm" />
          </div>
          <h2>Farmers&apos; Assistance for<br />Recovery and Modernization<br />(FARM) Program</h2>
        </div>
      </div>
      <div className="right-side-container">
        <div className="text-container">
          <div className="logo-banner-container">
            <img src="/static/images/logo-banner.png" className="logo-banner" />
            <img src="/static/images/farm-icon.png" className="logo-sm" />
          </div>
          <h2>Farmers&apos; Assistance for<br />Recovery and Modernization<br />(FARM) Program</h2>
        </div>
        <div className="text-container-desktop">
          <h2>Mag rehistro na para <br />sa</h2>
          <h2><strong>Farmers&apos; Assistance for<br />Recovery and Modernization<br />(FARM) Program</strong></h2>
        </div>
        <div className="button-container">
          <CodeForm />
        </div>
      </div>
    </RegisterHomePageContainer>
  );
};

export default RegisterHomepage;
