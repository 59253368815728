import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Form, message } from "antd";
import { daysOptions, monthsOptions, yearOptions } from "constant/selectConstants";
import moment from "moment";
import IDTable from "./IDTable";
import { getCitizenByCardNumber, getCitizensByLastNameAndBirthdate } from "services/citizen";
// import { useNavigate } from "react-router-dom";
import { BirthdateSelectContainer, FormItem, InputDateSelectField, InputTextFields } from "pages/Register/components/InputField.styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;

  .search-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ant-row {
      margin: 0;
    }
  }

  .FormItem-Birthdate {
    .ant-form-item-label {
      padding-bottom: 0;
    }
    .birthdate-container {
      padding-top: 0;
      .ant-form-item {
        flex-direction: row;
      }
    }
  }

  .search-btn {
    border-radius: 8px;
    width: 100%;

    &.ant-btn-primary {
      background-color: #E7B110;
      color: #ffffff;
      border-color: #E7B110;

      span {
        color: #ffffff;
      }

      &:hover,
      &:focus {
        background-color: #E7B110;
        border-color: #E7B110;
      }
    }
  }

  .bp-logo {
    width: calc(100% - 80px);
    margin: 0 auto;
    z-index: 5;

    @media only screen and (min-width: 900px) {
      width: 524px;
      margin-bottom: 30px;
    }
  }

  .bg {
    position: fixed;
    width: 55vw;
    bottom: 0;

    @media only screen and (max-width: 899px) {
      display: none;
    }
  }
`;
const FormContainer = styled.div`
  .search-by-select {
    flex-direction: column;
  }
  @media only screen and (min-width: 768px) {
    width: 65vw;
    margin: 0 auto;
    .search-by-select {
      flex-direction: row;
    }
  }

  @media only screen and (min-width: 900px) {
    width: 50vw;
    margin: 0 auto;
  }
`;

const PAGE_SIZE = 10;

function SearchPage() {
  // const navigate = useNavigate();
  const [form] = Form.useForm();

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchBy, setSearchBy] = useState('lastName'); // lastName | userCode

  const resendInterval = useRef(null);


  useEffect(() => {
    const year = form?.getFieldValue("year");
    const month = form?.getFieldValue("month");
    const date = form?.getFieldValue("date");

    if (date) {
      const dateOptions = daysOptions(month, year);

      if (!dateOptions.find(d => d.value === date)) {
        form?.setFieldsValue({ date: undefined });
      }
    }
  }, [form?.getFieldValue("month"), form?.getFieldValue("year")]);

  const handleSearch = useCallback(async () => {
    if (resendInterval.current) {
      clearInterval(resendInterval.current)
    }
    if (searchBy === 'lastName') {
      const last_name = form?.getFieldValue("last_name");
      const birth_date = moment(
        `${form?.getFieldValue("year")}-${form?.getFieldValue("month")}-${form?.getFieldValue("date")}`,
        "YYYY-MM-DD",
        false,
      ).format("YYYY-MM-DD");

      try {
        setLoading(true);
        await getCitizensByLastNameAndBirthdate(last_name, birth_date, PAGE_SIZE, PAGE_SIZE * (page - 1)).then(data => {
          setData(data?.results || []);
          setTotal(data?.count || 0);
        });
      } catch (error) {
        message.error(error);
      } finally {
        setLoading(false);
      }
    } else if (searchBy === 'userCode') {
      const idCardNumber = form?.getFieldValue("user_code");
      try {
        setLoading(true);
        const citizen = await getCitizenByCardNumber(idCardNumber);
        let results = [];
        if (citizen?.id) {
          results = [citizen];
        }
        setData(results);
        setTotal(results.length);
      } catch (error) {
        message.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [
    searchBy,
    page,
    form?.getFieldValue("last_name"),
    form?.getFieldValue("year"),
    form?.getFieldValue("month"),
    form?.getFieldValue("date"),
  ]);

  return (
    <Wrapper>
      <img src="/static/images/logo-banner.png" className="bp-logo" />
      <FormContainer>
        <FormItem
          className="search-by-select"
          label="Search by"
        >
          <InputDateSelectField
            value={searchBy}
            options={[
              {
                value: 'lastName',
                label: 'Last name / Birthdate'
              },
              {
                value: 'userCode',
                label: 'User Code'
              }
            ]}
            onChange={(value) => {
              setSearchBy(value);
            }}
          />
        </FormItem>
        <Form
          form={form}
          className="search-form"
          onFinish={handleSearch}
          layout="vertical"
        >
          {
            searchBy === 'userCode' && (
              <FormItem label="User Code" name="user_code" rules={[{ required: true, message: "User code is required" }]}>
                <InputTextFields name="user_code" />
              </FormItem>
            )
          }

          {
            searchBy === 'lastName' && (
              <>
                <FormItem label="Apelyido" name="last_name" rules={[{ required: true, message: "Last name is required" }]}>
                  <InputTextFields name="last_name" />
                </FormItem>
                <FormItem label="Birthdate" className="FormItem-Birthdate">
                  <BirthdateSelectContainer className="birthdate-container">
                    <FormItem
                      className="personal-info-date-select"
                      label="Year"
                      name="year"
                      rules={[{ required: true, message: "Year is required." }]}>
                      <InputDateSelectField options={yearOptions()} showSearch allowClear onChange={e => setSelectedYear(e)} />
                    </FormItem>
                    <FormItem
                      className="date-select"
                      label="Month"
                      name="month"
                      rules={[{ required: true, message: "Month is required." }]}>
                      <InputDateSelectField
                        options={monthsOptions()}
                        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        allowClear
                        onChange={e => setSelectedMonth(e)}
                      />
                    </FormItem>
                    <FormItem
                      className="date-select"
                      label="Date"
                      name="date"
                      rules={[{ required: true, message: "Date is required." }]}>
                      <InputDateSelectField
                        options={daysOptions(selectedMonth, selectedYear)}
                        disabled={!selectedYear || !selectedMonth}
                        showSearch
                        allowClear
                      />
                    </FormItem>
                  </BirthdateSelectContainer>
                </FormItem></>
            )
          }
          <Button type="primary" className="search-btn" htmlType="submit">
            Search
          </Button>
          {/* <Button type="link" onClick={() => navigate('/scan')}>
            Back to Scan
          </Button> */}
          <IDTable
            data={data}
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              pageSize: PAGE_SIZE,
              current: page,
              defaultCurrent: page,
              onChange: pg => setPage(pg),
              total,
            }}
            resendInterval={resendInterval}
          />
        </Form>
      </FormContainer>
    </Wrapper>
  );
}

export default SearchPage;
