import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    justify-content: normal;
    background-color: initial;
    padding: 0;
  }
`;

export const MobileBackground = styled.div`
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 0rem;

  @media only screen and (min-width: 320px) {
    bottom: -4rem;
  }

  @media only screen and (min-width: 390px) {
    bottom: 0rem;
  }

  @media only screen and (min-width: 480px) and (orientation: portrait) {
    bottom: -9.5rem;
  }

  @media only screen and (min-width: 768px) {
    bottom: -17.5rem;
  }

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

export const MobileGradient = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

export const LeftSideContainer = styled.div`
  display: none;

  @media only screen and (min-width: 900px) {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50vw;
    height: 100vh;
    background-image: url(/static/images/register-bg-top-cropped.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;

    h2 {
      margin-top: 40px;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 18px;
      color: #4D4D4D;
      text-align: center;
      font-weight: 700;
    }
  }
`;

export const BPLogo = styled.img`
  width: 272px;
  margin: 0 auto;
  margin-top: 4rem;
  z-index: 5;

  @media only screen and (min-width: 390px) {
    margin-top: 5.5rem;
  }

  @media only screen and (min-width: 480px) and (orientation: portrait) {
    margin-top: 2rem;
    margin-left: 2rem;
  }

  @media only screen and (min-width: 768px) {
    margin: 0 auto;
    margin-top: 40px;
  }
`;

export const RightSideContainer = styled.div`
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  margin-top: 2rem;

  @media only screen and (min-width: 390px) {
    margin-bottom: 4rem;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11rem;
  }

  @media only screen and (min-width: 900px) {
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    width: 50vw;
    height: 100vh;
    margin: 0 auto;
    background-color: #107400;
  }
`;

export const TextContainer = styled.div`
  text-align: left;

  @media only screen and (min-width: 768px) {
    width: 55rem;
  }

  @media only screen and (min-width: 900px) {
    width: 100%;
    display: flex;
  }
`;

export const Heading2 = styled.h2`
  line-height: 30px;
  font-size: 18px;
  color: #ffffff;

  @media only screen and (min-width: 320px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  @media only screen and (min-width: 900px) {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
`;

export const Strong = styled.strong`
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const MobileRegisterButton = styled.button`
  background-color: #E7B110;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: fit-content;

  @media only screen and (min-width: 900px) {
    display: none;
  }

  span {
    color: #ffffff;

    @media only screen and (min-width: 768px) {
      font-size: 1.35rem;
      padding: 1.15rem;
    }
  }
`;

export const RegisterButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  gap: 5rem;
  height: fit-content;

  @media only screen and (max-width: 899px) {
    display: none;
  }

  span {
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 600;
    padding: 0;
  }
`;

export const Gradient = styled.div`
  width: 45vw;
  position: fixed;
  bottom: -2.5rem;

  @media only screen and (max-width: 899px) {
    display: none;
  }
`;
