import React, { useState, useEffect, useRef } from "react";
import { Form, Input, InputNumber } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

const OTPInputField = styled(Input)`
  width: 40px;
  height: 70px;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin: 20px 3px;
  background-color: #f0f0f0;

  &:focus {
    border: 1px solid #107400;
    box-shadow: 0px 0px 4px 0px #5d4037;
    background-color: #ffffff;
  }

  &.otp-input::-webkit-inner-spin-button,
  &.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.otp-input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const OTPInput = ({ length = 6, autoFocus = true, onComplete }) => {
  const [otp, setOTP] = useState(Array(length).fill(""));
  const lastInputRef = useRef(null);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      if (index < length - 1 && value !== "") {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        nextInput && nextInput.focus();
      }
    }
  };

  const handleKeyUp = (e, index) => {
    const value = e.target.value;
    if (e.keyCode === 8 && value === "") {
      const newOTP = [...otp];
      newOTP[index] = "";
      setOTP(newOTP);
      if (index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        prevInput && prevInput.focus();
      }
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text/plain").slice(0, length);
    const newOTP = [...otp];
    for (let i = 0; i < length; i++) {
      newOTP[i] = pasteData[i] || "";
    }
    setOTP(newOTP);
    if (pasteData.length < length) {
      const nextInput = document.getElementById(`otp-input-${pasteData.length}`);
      nextInput && nextInput.focus();
    }
  };

  useEffect(() => {
    const otpValue = otp.join("");
    const lastDigitEntered = otp[length - 1] !== "";
    if (lastDigitEntered) {
      lastInputRef.current = true;
      onComplete && onComplete(otpValue);
    } else {
      lastInputRef.current = false;
    }
  }, [otp, length, onComplete]);

  return (
    <div>
      <Form.Item style={{ margin: 0 }}>
        {otp.map((digit, index) => (
          <OTPInputField
            className="otp-input"
            type="number"
            key={index}
            id={`otp-input-${index}`}
            value={digit}
            onChange={e => handleChange(e, index)}
            onKeyUp={e => handleKeyUp(e, index)}
            onPaste={handlePaste}
            autoFocus={autoFocus && index === 0}
            maxLength={1}
            inputMode="numeric"
          />
        ))}
      </Form.Item>
    </div>
  );
};

OTPInput.propTypes = {
  length: PropTypes.number,
  autoFocus: PropTypes.bool,
  onComplete: PropTypes.func,
};

export default OTPInput;
