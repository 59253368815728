import React, { Fragment, useContext, useState } from "react";
import { NewCitizenContext } from "../../NewCitizenContext";
import { Divider, Typography } from "antd";
import { FormItem, InputSelectField, InputTextFields } from "pages/Register/components/InputField.styled";
import NumericInput from "components/form/fields/NumericInput";
import styled from "styled-components";
import { getBarangayOption } from "services/public_registration.service";
import { metaOptions, yesNoOptions } from "jsonLib";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-btn {
    background-color: #107400 !important;
    span {
      color: #FFFFFF;
      font-size: 20px;
      line-height: 22px;
    }
  }
`;
const StyledParcelNumberTitle = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
  font-weight: 600;
  color: #107400;
`;
const FarmParcelsForm = () => {
  const {
    municipalityOptions,
    stateDispatchMain,
    state: {
      citizenDetails
    },
  } = useContext(NewCitizenContext);
  const { farm_parcels } = citizenDetails;
  const [barangayOptions, setBarangayOptions] = useState({});

  const getBarangayOptions = async (municipalityId) => {
    try {
      const barangayOption = await getBarangayOption(municipalityId);
      const filteredBarangayOption = barangayOption.results.map(barangay => ({
        ...barangay,
        label: barangay?.name,
        value: barangay?.id,
      }));
      setBarangayOptions(prevBgryOptions =>
      ({
        ...prevBgryOptions,
        [municipalityId]: filteredBarangayOption
      })
      )
    } catch (err) {
      console.error(err);
    }
  }

  const onChangeFarmParcel = (key, value, index) => {
    const farmParcels = farm_parcels;
    const farmParcel = {
      ...farmParcels[index],
      [key]: value
    }

    if (key === 'municipality') {
      getBarangayOptions(value);
      farmParcel.barangay = null;
    }

    farmParcels[index] = farmParcel;

    stateDispatchMain({
      key: "citizenDetails",
      payload: {
        ...citizenDetails,
        farm_parcels: farmParcels,
      },
    });
  };
  /* const onDeleteParcel = (index) => {
    const farmParcels = [...farm_parcels];
    farmParcels.splice(index, 1);

    stateDispatchMain({
      key: "citizenDetails",
      payload: {
        ...citizenDetails,
        farm_parcels: farmParcels,
      },
    });
  } */

  /* const onAddFarmParcel = () => {
    const farmParcels = [...farm_parcels, defaultFarmParcel];

    stateDispatchMain({
      key: "citizenDetails",
      payload: {
        ...citizenDetails,
        farm_parcels: farmParcels,
      },
    });
  } */

  return (
    <>
      {
        farm_parcels.map((farmParcel, index) => (
          <Fragment key={index}>
            <Divider />
            <TitleContainer>
              <StyledParcelNumberTitle>Farm Parcel no. {index + 1}</StyledParcelNumberTitle>
              {/* {
                farm_parcels.length > 1 ? (
                  <Button
                    onClick={() => onDeleteParcel(index)}
                    type="primary"
                    shape="circle"
                    size="small"
                  >×</Button>
                ) : null
              } */}
            </TitleContainer>
            <FormItem
              label="Lungsod/Munisipyo"
              rules={[{ required: true, message: "Lungsod/Munisipyo is required" }]}>
              <InputSelectField
                key="municipality"
                bordered={false}
                onChange={(_, option) => {
                  if (option?.value) {
                    onChangeFarmParcel('municipality', option.value, index);
                  }
                }}
                options={municipalityOptions}
                defaultValue={farmParcel.municipality || null}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </FormItem>
            <FormItem
              label="Barangay"
              rules={[{ required: true, message: "Barangay is required" }]}>
              <InputSelectField
                bordered={false}
                onChange={(_, option) => {
                  if (option?.value) {
                    onChangeFarmParcel('barangay', option.value, index);
                  }
                }}
                options={barangayOptions?.[farmParcel.municipality] || []}
                disabled={!barangayOptions?.[farmParcel.municipality]?.length}
                showSearch
                defaultValue={farmParcel.barangay || null}
                notFoundContent="Loading"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </FormItem>
            {
              [2, 3, 4].includes(farmParcel.ownership_type) ?
                (
                  <FormItem label={farmParcel.ownership_type === 4 ? "Please specify" : "Name of Land Owner"} rules={[{ required: true, message: "Household head relationship is required" }]}>
                    <InputTextFields
                      onChange={e => {
                        onChangeFarmParcel('ownership_type_charfield', e.target.value, index);
                      }
                      }
                    />
                  </FormItem>
                ) : null
            }

            <FormItem
              label="Crop / Commodity / Livestock / Poultry"
            >
              <InputTextFields
                onChange={e => {
                  onChangeFarmParcel('commodity', e.target.value, index);
                }}
              />
            </FormItem>

            <FormItem
              label="Specify type Animal for Livestock and Poultry"
            >
              <InputTextFields
                onChange={e => {
                  onChangeFarmParcel('commodity', e.target.value, index);
                }}
              />
            </FormItem>

            <FormItem label="No. of Head">
              <NumericInput
                defaultValue={0}
                maxLength={2}
                onChange={(value) => {
                  onChangeFarmParcel('farm_size', value, index);
                }}
              />
            </FormItem>

            <FormItem label="Farm size (ha)">
              <NumericInput
                defaultValue={0}
                maxLength={2}
                onChange={(value) => {
                  onChangeFarmParcel('head_count', value, index);
                }}
              />
            </FormItem>
          </Fragment>
        ))
      }
    </>
  );
}
export default FarmParcelsForm;
