import { Button, Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Title from 'antd/lib/typography/Title';
import ProgressOverlay from 'components/progress/ProgressOverlay';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCitizen, getCitizenByCardNumber } from 'services/citizen';
import styled from 'styled-components';

const Header = styled(Title)`
  color: white !important;
  margin: 0 !important;
`;

const TopOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;

  align-items: center;
  justify-content: center;

  height: 60px;
  width: 100vw;
`;

const BottomOverlay = styled.div`
  position: absolute;
  bottom: 0;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;
  flex-direction: column;

  height: 270px;
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100vw;

  .button-search-by {
    margin-top: 10px;
    span {
      font-size: 90%;
      color: #FFFFFF;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const LeftOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;
  top: 225px;

  left: 0;

  align-items: center;
  justify-content: center;

  justify-content: center;
  height: calc(100% - 195px - 300px);
  width: 10%;
`;

const RightOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  top: 225px;

  right: 0;

  align-items: center;
  justify-content: center;

  height: calc(100% - 195px - 300px);
  width: 10%;
`;

const SearchButton = styled(Button)`
  z-index: 2;
  margin-top: 15px;
  border-radius: 10px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #E7B110 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
  color: #FFFFFF;
  span {
    color: #FFFFFF;
  }
`;

const IdSearchInput = styled(Input)`
  margin-top: 10px;
`;

const SearchForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onSearch = async (values) => {
    try {
      setIsLoading(true);
      const idCardNumber = values.idCardNumber;

      if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(idCardNumber)) {
        const citizen = await getCitizen(idCardNumber);
        setIsLoading(false);
        setError('');
        navigate(`/citizen/${citizen.id}`);
      } else {
        const citizen = await getCitizenByCardNumber(idCardNumber);
        setIsLoading(false);
        setError('');
        navigate(`/citizen/${citizen.id}`);
      }


    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setError('No citizen data found');
    }
  }

  const onFinishFailed = errorInfo => {
    setError('There was an error');
    console.error("Failed:", errorInfo);
  };

  return (
    <div style={{ zIndex: 5 }}>
      {
        isLoading && <ProgressOverlay />
      }
      {
        error && <ProgressOverlay
          title="Error"
          titleProps={{
            type: 'danger'
          }}
          description={error}
          onClose={() => setError('')}
          isSpin={false}
        />
      }
      <TopOverlay>
        <Header level={3}>Scan QR Code</Header>
      </TopOverlay>
      <LeftOverlay />
      <RightOverlay />
      <BottomOverlay>
        <Header level={4}>Couldn&apos;t find Citizen?</Header>
        <Header level={5}>Enter ID number instead.</Header>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            minWidth: "90%",
          }}
          onFinish={onSearch}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            idCardNumber: ''
          }}
        > <FormItem name="idCardNumber">
            <IdSearchInput placeholder="Enter ID Number"
              onChange={e =>
                form.setFieldsValue((values) => ({
                  ...values,
                  idCardNumber: e.target.value
                }))
              }
            />
          </FormItem>
          <FormItem>
            <SearchButton htmlType="submit" block>
              Search Citizen
            </SearchButton>
          </FormItem>
          <FormItem>
            <Button
              size='small'
              className="button-search-by"
              type="text"
              onClick={() => navigate('/search')}
              block
            >
              Search by Last Name
            </Button>
          </FormItem>
        </Form>
      </BottomOverlay>
    </div>
  );
};
export default SearchForm
