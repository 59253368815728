import { Button, Spin, Typography } from 'antd';
import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 14px;
  top: 0;
  left: 0;
  .content {
    border-radius: 43px;
    background: #FFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 90vw;
    text-align: center;
    padding: 20px;
    .ant-spin-dot-item {
      background-color: #E7B110;
    }
  }
  z-index: 999;
`
const ProgressOverlay = ({
  description = 'Digging up our records',
  isSpin = true,
  onClose = null,
  open = true,
  title = 'Just a Moment',
  titleProps = {},
}) => {

  if (!open) {
    return null;
  }

  return (
    <Container>
      <div className="content">
        <Typography.Title level={3} {...titleProps}>
          {title}
        </Typography.Title>
        <Typography.Paragraph>
          {description}
        </Typography.Paragraph>
        {isSpin && <Spin />}
        {onClose && <Button onClick={onClose}>Close</Button>}
      </div>
    </Container>
  );
}
export default ProgressOverlay;
